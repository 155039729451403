@import 'src/design-system/styles/typography.mixins';

// после решения вопроса рассинхронизации шрифтов нужно убрать

.passwordInput input {
  padding-right: 45px !important;
}

// ---

.forgotPasswordLink {
  @include font-style-tiny;

  color: var(--text-link);
}
