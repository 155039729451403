@import 'src/design-system/styles/typography.mixins';

.text {
  @include font-style-small;
  color: var(--text-30);
  margin: 0;

  & p:last-of-type {
    margin-bottom: 0;
  }
}

.error {
  @include font-style-tiny;
  color: var(--system-error);
}
