@import 'src/design-system/styles/typography.mixins';

.message {
  max-width: 184px;
  margin-top: 8px;

  @include font-style-small($font-weight-bold);
  color: var(--system-error);
  text-align: center;
}
