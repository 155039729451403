@import 'src/design-system/styles/typography.mixins';

.mainWrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
  background: transparent;
  border: none;
  padding: 0;
  text-align: start;
  cursor: unset;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: 0;
  padding: 0;
}

.text {
  @include font-style-small;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--text-10);
  user-select: none;
}

.help {
  @include font-style-tiny;
  color: var(--text-30);
  user-select: none;
}

.label {
  color: var(--text-20);
}

.value {
  padding-top: 4px;
}

.tooltip {
  cursor: pointer;

  &:hover {
    opacity: 1;

    & .suffix {
      opacity: .8;
    }
  }
}

.suffix {
  color: var(--text-30);
  border: none;
  outline: none;
  background-color: transparent;
}
