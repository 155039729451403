@import 'src/design-system/styles/typography.mixins';
@import 'src/styles/animation-variables';

.container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  text-align: center;
  outline: none;

  & .icon {
    color: var(--text-40);

    &:hover {
      opacity: .8;
    }
  }
}

.icon {
  margin-right: 4.5px;

  & path {
    transition: $defaultTransitionDuration $smoothTransitionTiming fill;
  }
}

.label {
  @include font-style-small;
  color: var(--text-link);

  &:hover {
    color: var(--text-primary);
  }
}

.popperContainer {
  max-width: 376px;
}
