.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.code {
  padding: 12px;
  border-radius: 10px;
  background: var(--static-white);
}
