@import 'src/design-system/styles/typography.mixins';

.root {
  display: flex;
  flex-direction: column;

  & img {
    height: 64px;
    object-fit: contain;
    margin: auto auto 8px;
  }

  & h4 {
    @include font-style-subtitle($font-weight-bold);
    color: var(--text-10);
    margin: auto;
  }
}
