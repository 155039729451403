@import 'src/design-system/styles/layout-variables';
@import 'src/design-system/styles/typography.mixins';

.paymentLoader {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.title {
  @include font-style-h4($font-weight: $font-weight-regular);
  margin: 0 0 24px 0;
}

.sectionContainer {
  display: flex;
  flex-direction: column;
  padding: $content-indent;
}

.paymentContent {
  display: flex;
  flex-grow: 1;
  gap: 24px;
}

.widget {
  flex-grow: 1;
}

.widgetContent {
  flex-grow: 1;
  padding: 0;

  & :global .providerIframe {
    width: 100%;
    height: 100%;
    padding: 24px;
    text-align: center;
    background: var(--shape-10);
  }

  & :global .providerIframe h5 {
    @include font-style-h4;
    margin: 0 0 26px 0;
    padding: 0;
  }

  & :global .providerIframe button {
    display: flex;
    margin: 0 auto 22px 0;
  }

  & :global .providerIframe button span {
    margin-right: 8px;
  }


  // Лоадер. Убираем из потока, чтобы при его исчезновении контент не скакал
  & :global .providerIframe > table {
    position: absolute;
  }

  & :global .providerIframe iframe {
    width: 100%;
  }
}

.topUpAside {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 250px;
  width: 250px;
}

.balanceHeader {
  display: flex;
  flex-direction: column;

  margin: 0;

  font-family: $font-family-default;
  @include font-style-tiny($font-weight-bold);
  text-transform: uppercase;
  color: var(--text-20);
}

.balanceAmount {
  @include font-style-h4($font-weight-regular);
  color: var(--text-10);
}

.topUpForm {
  display: grid;
  grid-gap: 24px;
  align-content: start;

  max-width: 303px;
  margin: 0 auto;
  padding: 56px 16px;

  & button + a,
  button:not(:global(.tooltipBtn)) + button {
    margin-top: -16px;
  }
}

.applicationContainer {
  justify-items: center;
  text-align: center;

  & img + h4 {
    margin-top: -13px;
  }

  & h4 {
    @include font-style-subtitle($font-weight-bold);

    margin: 0;
  }

  & p {
    color: var(--text-20);
    @include font-style-default;

    margin: 0;
  }

  & .cancelableInfo {
    @include font-style-small;

    margin-top: -16px;
    color: var(--system-error);
  }
}

.formHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.iconSuccess,
.iconCross {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 64px;
  height: 64px;

  border-radius: 50%;
  background-color: var(--shape-40);
}

.iconSuccess {
  color: var(--system-success);
}

.iconCross {
  color: var(--system-error);
}

.reasonsContainer {
  white-space: pre-wrap;
}

.noSelectedProvider {
  @include font-style-subtitle($font-weight-bold);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 32px;

  & h2 {
    margin: 0;
  }
}
