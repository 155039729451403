.providerFormSkeletonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 56px 0 50px 0;

  & .skeletonImageTitle {
    margin-top: 14px;
    margin-bottom: 38px;
  }

  & .skeletonWidgetList {
    margin-bottom: 25px;

    & .skeletonWidgetItem + .skeletonWidgetItem {
      margin-top: 8px;
    }
  }
}
