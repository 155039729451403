@import 'src/design-system/styles/typography.mixins';

.mainWrapper {
  display: grid;
  grid-template-columns: 1fr 40px;
  min-width: 270px;
}

.label {
  @include font-style-small;

  color: var(--text-20);
  margin: 0;
  grid-column: 1/2;
}

.timer {
  @include font-style-small;

  color: var(--text-10);
  padding-top: 4px;
  grid-column: 1/2;
}

.spinner {
  justify-content: end;
  grid-column: 2/3;
  grid-row: 1/3;
}
