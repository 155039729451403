.fakeAmountInput {
  position: fixed;

  & input {
    height: 0;
    width: 0;
    padding: 0;
    border: none;
  }
}
