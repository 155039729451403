.modalBody {
  padding: 14px;
  margin: 0 auto;
}

.video {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-height: 700px;
}

.spinner {
  width: 100%;
  height: 289px;
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 289px;
  margin: 0;
}
